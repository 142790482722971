// Initialize first
@import '../init';

//Hero Section
.hero {
  position: relative;
  height: auto;

  .hero__background {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: -1;

    &.hero__background--flat {
      top: toRem(-102);
      right: 0;
      max-width: 72.0833%;
      margin: 0;
      margin-right: auto;
      height: toRem(733);
      background-color: $color-tertiary;

      @media screen and (min-width:toRem(1699.8)) {
        max-width: 66%;
      }

      @media screen and (min-width:toRem(1999.8)) {
        max-width: 64%;
      }

      @media screen and (min-width:toRem(2199.8)) {
        max-width: 62%;
      }

      @media screen and (min-width:toRem(2499.8)) {
        max-width: 61%;
      }

      @include media-breakpoint-down(xxl) {
        max-width: 70%;
      }

      @include media-breakpoint-down(lg) {
        max-width: 100%;
        height: toRem(1000);
      }

      @include media-breakpoint-down(md) {
        height: toRem(862);
      }

      @include media-breakpoint-down(xs-2) {
        height: toRem(760);
      }
    }

    &.hero__background--pattern {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: toRem(1440);

      .hero__pattern-bg {
        position: absolute;
        top: toRem(135);
        left: 45.8159%;
        display: block;

        @include media-breakpoint-down(xxl) {
          left: 39%;
        }

        @include media-breakpoint-down(xl) {
          left: 34%;
        }

        @include media-breakpoint-down(lg) {
          left: unset;
          right: 0;
          margin-right: 43%;
          top: toRem(552);
        }

        @include media-breakpoint-down(md) {
          max-width: 70%;
          right: unset;
          margin-right: 0;
          left: 0;
        }

        @include media-breakpoint-down(xs-2) {
          max-width: 75%;
        }
      }
    }
  }

  .container {
    z-index: 20;
    padding-top: 10rem;
    padding-bottom: toRem(48);
    margin-top: 4rem;

    @include media-breakpoint-down(lg) {
      padding-top: 8rem;
      margin-top: 0rem;
    }
  

  }

  .hero__content {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      margin-bottom: toRem(24);
    }

    .hero__title {
      color: $color-text-darker;
      max-width: toRem(520);
      font-size: 4rem;
      @include media-breakpoint-down(md) {
        font-size: 3rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }

    .hero__subtitle {
      max-width: toRem(520);
      color: $color-secondary;
      margin-top: toRem(12);
      margin-bottom: toRem(22);

      @include media-breakpoint-down(lg) {
        margin-bottom: toRem(24);
      }
    }

    .hero__details {
      max-width: toRem(452);
      color: $color-text-dark;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      @include media-breakpoint-down(md) {
        font-size: toRem(18);
      }
    }
  }

  .hero-forms {
    position: relative;
    z-index: 3;

    @include media-breakpoint-down(lg) {
      margin-bottom: toRem(32);
    }

    .hero-forms__forms {
      position: relative;
      z-index: 10;
      margin-top: toRem(62);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: toRem(12) toRem(12) toRem(12) toRem(24);
      gap: 56px;

      height: auto;
      width: auto;
      max-width: max-content;
      min-height: toRem(89);
      background: $color-white;
      border-radius: toRem(6);

      @include media-breakpoint-down(lg) {
        display: none;
      }

      .line {
        width: 1px;
        height: toRem(65);
        background: $color-text-lighter;
      }

      //custom select
      .custom-datepicker {
        position: relative;
        max-width: toRem(127);
        cursor: pointer;

        .custom-datepicker__box {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: toRem(2);
          width: auto;
          min-width: toRem(145);
          overflow: hidden;

          .custom-datepicker-box__head {
            display: flex;
            gap: toRem(24);
            justify-content: space-between;
            align-items: center;
          }

          .custom-datepicker__icon-date {
            width: auto;
            height: auto;
            max-width: toRem(20);
            max-height: toRem(20);
          }

          .custom-datepicker__title {
            color: $color-text-light;
          }
        }

        .custom-datepicker__input {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          padding: 0;
          color: $color-text-darker;
          cursor: pointer;
        }
      }
    }

    .hero-forms__links {
      display: none;

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }

  .hero__illustration {
    position: absolute;
    z-index: -1;
    top: 7rem;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: toRem(1440);
    margin: 0 auto;
    height: 100%;

    @include media-breakpoint-down(lg) {
      position: relative;
      top: unset;
    }

    .carousel-inner{
      width: 50%;
      margin-left: auto;
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin-left: 0;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
      }
    }

    .hero__illustration-src {
      position: relative;
      display: block;
      margin-left: auto;
      border: toRem(2);
      height: 600px;
      width: 90%;
      object-fit: cover;

      @include media-breakpoint-down(xl) {
        margin-right: toRem(-120);
        height: 600px;
      }

      @include media-breakpoint-down(lg) {
        margin-right: toRem(-24);
        height: 600px;
      }

      @include media-breakpoint-down(md) {
        // display: none;
        height: 400px;
      }
    }
    .hero__illustration-src-2 {
      position: relative;
      display: block;
      margin-left: auto;
      border: toRem(2);
      height: 600px;
      width: 90%;
      object-fit: cover;

      @include media-breakpoint-down(xl) {
        margin-right: toRem(-120);
        height: 600px;
      }

      @include media-breakpoint-down(lg) {
        margin-right: toRem(-24);
        height: 600px;
      }

      @include media-breakpoint-down(md) {
        // display: none;
        height: 400px;
      }
    }

    .hero__illustration-src--mobile {
      position: relative;
      display: none;
      margin-left: auto;
      border: toRem(2);

      @include media-breakpoint-down(md) {
        display: block;
        max-width: 80%;
        margin-right: toRem(-24);
      }
    }
  }

  .container--partner {
    position: relative;
    z-index: 2;
    padding-top: toRem(84);
    padding-bottom: toRem(48);

    @include media-breakpoint-down(xxl) {
      padding-top: toRem(48);
      padding-bottom: toRem(32);
    }

    @include media-breakpoint-down(xxl) {
      padding-top: toRem(32);
      padding-bottom: toRem(16);
    }

    .hero__partner {
      width: max-content;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: toRem(0);
      gap: toRem(32);

      @include media-breakpoint-down(xxl) {
        flex-direction: column;
        gap: toRem(24);
        justify-content: flex-start;
        align-items: flex-start;
      }

      @include media-breakpoint-down(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: unset;
        align-items: flex-start;
      }

      .hero-partner__images {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: toRem(0);
        gap: toRem(32);

        @include media-breakpoint-down(xs-2) {
          gap: toRem(16);
        }

        img {

          // @include media-breakpoint-down(md) {    
          //   max-width: toRem(118);
          //   max-height: toRem(22);
          // }
          @include media-breakpoint-down(sm) {
            max-width: toRem(118);
            max-height: toRem(22);
          }

          @include media-breakpoint-down(xs-2) {
            max-width: toRem(100);
          }
        }
      }
    }
  }
}

//About us Section
.about-us {
  position: relative;
  z-index: 2;

  //pattern on cta section dekstop responsive width size more than 768px
  .about-us__background {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: 1;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &.about-us__background--pattern-1 {
      left: 0;
      right: 0;
      top: toRem(134);
      bottom: 0;

      .about-us__pattern-bg {
        display: block;
      }
    }

    &.about-us__background--pattern-2 {
      left: 0;
      right: 0;
      top: toRem(209);
      bottom: 0;

      .about-us__pattern-bg {
        display: block;
        margin-left: auto;
      }
    }
  }

  //pattern on cta section dekstop responsive width size less than 768px
  .about-us__background--mobile {
    position: absolute;
    width: 100vw;
    display: none;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: 1;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &.about-us__background--pattern-1 {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .about-us__pattern-bg {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
      }
    }

    &.about-us__background--pattern-2 {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .about-us__pattern-bg {
        display: block;
        position: absolute;
        right: 0;
        top: toRem(118.72);
      }
    }
  }

  .container {
    position: relative;
    padding-top: toRem(87);
    display: flex;
    flex-direction: column;
    gap: toRem(24);
    text-align: center;
    align-items: center;
    justify-content: center;
    z-index: 10;
    margin-top: 17rem;

    @include media-breakpoint-down(lg) {
      padding-top: toRem(48);
      padding-bottom: toRem(48);
      margin-top: 4rem;
    }

    @include media-breakpoint-down(md) {
      padding-top: toRem(48);
      padding-bottom: toRem(48);
      margin-top: 10rem;
    }
    @include media-breakpoint-down(sm) {
      padding-top: toRem(48);
      padding-bottom: toRem(48);
      margin-top: 7rem;
    }
  }

  .about-us__title {
    color: $color-text-darker;
    width: auto;
    max-width: toRem(560);
  }

  .about-us__details {
    color: $color-text-dark;
    width: auto;
    max-width: toRem(613);
  }
}

//Services Section
.services {
  position: relative;
  z-index: 1;
  padding: 10rem 0rem 6rem;
  margin-top: 7rem;

  @include media-breakpoint-down(lg) {
    padding: 6rem 0rem 4rem;
    margin-top: 0rem;
  }
  @include media-breakpoint-down(md) {
    padding: 4rem 0rem 2rem;
    margin-top: 0rem;
  }

  //pattern on cta section dekstop responsive width size more than 768px
  .services__background {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: -1;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &.services__background--pattern-1 {
      max-width: toRem(1440);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .services__pattern-bg {
        position: absolute;
        bottom: toRem(280);
        left: toRem(280);
        display: block;

        @include media-breakpoint-down(xxl) {
          left: toRem(250);
        }

        @include media-breakpoint-down(xl) {
          left: toRem(140);
        }

        @include media-breakpoint-down(lg-2) {
          left: toRem(100);
        }

        @include media-breakpoint-down(lg) {
          left: 0;
        }
      }
    }

    &.services__background--pattern-2 {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .services__pattern-bg {
        position: absolute;
        bottom: toRem(72);
        right: 0;
        display: block;

        @include media-breakpoint-down(lg) {
          bottom: 0;
        }
      }
    }
  }

  //pattern on cta section dekstop responsive width size more than 768px
  .services__background--mobile {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    display: none;
    pointer-events: none;
    margin: 0 auto;
    z-index: -1;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &.services__background--pattern {
      max-width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .services__pattern-bg {
        position: absolute;
        bottom: toRem(48);
        display: block;

        @include media-breakpoint-down(xs-2) {
          width: 100%;
          bottom: toRem(60);
        }
      }
    }
  }

  &__content {
    position: relative;
  }

  &__header {
    // position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - toRem(32));
    max-width: toRem(435);

    display: flex;
    flex-direction: column;
    gap: toRem(24);

    @include media-breakpoint-down(lg) {
      position: relative;
      top: unset;
      left: unset;
      width: 100%;
      margin-bottom: toRem(24);
      max-width: 100%;
      text-align: center;
      gap: toRem(16);
    }

    @include media-breakpoint-down(md) {
      padding-left: toRem(24);
      padding-right: toRem(24);
    }

    .services__title {
      color: $color-text-darker;
    }

    .services__subtitle {
      color: $color-text-dark;

      @include media-breakpoint-down(lg) {
        max-width: toRem(576);
        margin: 0 auto;
      }
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    z-index: 2;

    @include media-breakpoint-down(lg) {
      margin-left: toRem(-24);
      margin-right: toRem(-24);
      padding-left: toRem(24);
      padding-right: toRem(24);
      display: flex;
      gap: toRem(16);
      overflow-x: scroll;
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;

      /* Firefox */
      &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }
    }

    @include media-breakpoint-down(md) {
      padding-left: toRem(24);
      padding-right: toRem(24);
    }

    .card--service {
      &:nth-of-type(1) {
        grid-column: 2/3;
      }

      &:nth-of-type(2) {
        grid-column: 3/4;
      }

      &:nth-of-type(3) {
        // grid-row: 2/3;
        grid-column: 4/5;
      }

      &:nth-of-type(4) {
        grid-row: 2/3;
        grid-column: 1/2;
      }

      &:nth-of-type(5) {
        grid-row: 2/3;
        grid-column: 2/3;
      }

      &:nth-of-type(6) {
        grid-row: 2/3;
        grid-column: 3/4;
      }

      &:nth-of-type(7) {
        grid-row: 2/3;
        grid-column: 4/5;
      }

      // &:nth-of-type(8) {
      //   grid-row: 3/4;
      //   grid-column: 3/4;
      // }

      @include media-breakpoint-down(lg) {
        min-width: toRem(276);
        grid-column: unset !important;
        margin-bottom: 0 !important;
      }

      @include media-breakpoint-down(xs-2) {
        min-width: toRem(240);
        max-width: toRem(240);
        padding: toRem(24);
      }
    }
  }
}

//Appointment Section 
.appointment {
  position: relative;
  z-index: 2;
  background-color: $color-tertiary;

  //pattern on cta section dekstop responsive width size more than 576px
  .appointment__background {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &.appointment__background--pattern-1 {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .appointment__pattern-bg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }
    }

    &.appointment__background--pattern-2 {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .appointment__pattern-bg {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
      }
    }
  }

  .container {
    position: relative;
    z-index: 10;
    padding-top: toRem(88);
    padding-bottom: toRem(88);
    display: flex;
    flex-direction: column;
    gap: toRem(40);
    align-items: center;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-down(md) {
      padding-top: toRem(48);
      padding-bottom: toRem(48);
      gap: toRem(24);
    }
  }

  .appointment__title {
    color: $color-text-darker;
  }

  .appointment__content {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: toRem(40);

    @include media-breakpoint-down(xl) {
      gap: toRem(32);
    }

    @include media-breakpoint-down(lg) {
      gap: toRem(24);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      gap: toRem(20);
    }

    .card--step {
      position: relative;

      @include media-breakpoint-down(lg) {
        padding: toRem(24);
      }

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      .card-step__number {
        @include media-breakpoint-down(md) {
          top: toRem(-70);
          right: toRem(-8);
        }

        @include media-breakpoint-down(xs-2) {
          top: toRem(-16);
          font-size: toRem(200);
        }
      }
    }
  }
}

//Location Section
.location {
  position: relative;
  padding-top: toRem(88);
  padding-bottom: toRem(88);

  @include media-breakpoint-down(md) {
    padding-top: toRem(48);
    padding-bottom: toRem(0);
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: toRem(40);
    margin-bottom: toRem(40);

    @include media-breakpoint-down(md) {
      justify-content: center;
      margin: 0 auto toRem(32);
    }

    .location__title {
      display: flex;
      flex-direction: row;
      gap: toRem(24);
      padding-bottom: toRem(12);
      color: $color-text-darker;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        gap: toRem(8);
      }

      .location-title__name {
        position: relative;
        color: $color-primary;
        width: max-content;

        &::after {
          content: "";
          position: absolute;
          bottom: toRem(-12);
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: $color-text-lighter;
          pointer-events: none;
        }
      }
    }

    .slick__arrows {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__carousel {
    margin-bottom: toRem(48);
  }

  .slick-list {
    margin: 0 toRem(-12);
    padding: 0 10% 0 0 !important;
    overflow: hidden;

    @include media-breakpoint-down(xl) {
      padding: 0 30% 0 0 !important;
    }

    @include media-breakpoint-down(lg-2) {
      padding: 0 24% 0 0 !important;
    }

    @include media-breakpoint-down(lg) {
      padding: 0 0 0 0 !important;
    }

    .slick-slide {
      max-width: max-content !important;
    }
  }

  .slick-slide {
    padding: 0 toRem(12);
    box-sizing: border-box;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: toRem(40);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

//Features Section
.features {
  position: relative;
  z-index: 1;
  background: transparent;
  padding: toRem(88) 0 toRem(132);
  min-height: toRem(768);
  background-color: $color-lighter;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    padding: toRem(48) 0;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background: $color-tertiary;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    transform: translateX(-50%);
    height: 100%;

    @include media-breakpoint-down(lg) {
      transform: translateY(toRem(455));
    }

    @include media-breakpoint-down(lg) {
      transform: translateY(toRem(362));
    }

    @include media-breakpoint-down(xs-2) {
      transform: translateY(toRem(424));
    }
  }

  //pattern on cta section dekstop responsive width size more than 576px
  .features__background {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: 1;

    &.features__background--pattern {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .features__pattern-bg {
        position: absolute;
        bottom: 0;
        right: 0;

        @include media-breakpoint-down(lg) {
          bottom: unset;
          top: toRem(315);
        }

        @include media-breakpoint-down(md) {
          top: toRem(375);
          right: toRem(-28);
          max-width: toRem(164);
        }

        @include media-breakpoint-down(sm) {
          top: toRem(280);
          max-width: toRem(164);
        }

        @include media-breakpoint-down(xs-2) {
          max-width: toRem(120);
          top: toRem(365);
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 0;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }

  .features__header {
    grid-row: 1/2;
    grid-column: 2/3;
    padding-left: toRem(103);
    display: flex;
    flex-direction: column;
    gap: toRem(64);

    @include media-breakpoint-down(xl) {
      padding-left: toRem(32);
    }

    @include media-breakpoint-down(lg) {
      grid-row: 1/2;
      grid-column: 1/2;
      padding-left: 0;
      text-align: center;
      gap: toRem(24);
      padding-bottom: toRem(48);
    }

    .features__title {
      color: $color-text-darker;
      margin-bottom: toRem(24);
      max-width: toRem(450);

      @include media-breakpoint-down(lg) {
        margin: 0 auto toRem(16);
      }
    }

    .features__subtitle {
      color: $color-text-dark;

      @include media-breakpoint-down(lg) {
        max-width: toRem(576);
        margin: 0 auto;
      }

      @include media-breakpoint-down(md) {
        max-width: toRem(370);
      }
    }

    .features__stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: start;
      gap: toRem(98);

      @include media-breakpoint-down(lg) {
        gap: toRem(40);
        display: flex;
        justify-content: center;
      }

      @include media-breakpoint-down(md) {
        gap: toRem(64);
        display: flex;
        justify-content: center;
      }

      @include media-breakpoint-down(sm) {
        gap: toRem(24);
        display: flex;
        justify-content: center;
      }

      .feat-stats__item {
        display: flex;
        flex-direction: column;
        gap: toRem(4);
        align-items: flex-start;
      }

      .feat-stats__value {
        color: $color-text-darker;
      }

      .feat-stats__detail {
        color: $color-text-dark;
        max-width: toRem(130);
        text-align: left;
      }
    }

    .features__link {
      width: max-content;
      grid-column: 1/2;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .features__group {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(lg) {
      grid-row: 2/3;
      grid-column: 1/2;
    }

    @include media-breakpoint-down(md) {
      padding-top: toRem(48);
      gap: toRem(24);
    }

    .features__items {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: toRem(24);
      gap: toRem(24);
      max-width: toRem(448);

      @include media-breakpoint-down(lg) {
        max-width: toRem(520);
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 0;
        padding-left: toRem(24);
      }

      @include media-breakpoint-down(xs-2) {
        gap: toRem(16);
      }

      .ft-icons {
        img {
          width: auto;
          height: auto;
          max-width: toRem(56);
          max-height: toRem(56);
        }
      }

      .ft-detail {
        display: flex;
        flex-direction: column;
        gap: toRem(8);
      }

      .ft-title {
        color: $color-text-darker;
      }

      .ft-subtitle {
        color: $color-text-dark;
      }
    }
  }
}

//Testimonials Section
.testimonials {
  position: relative;
  z-index: 1;

  .container {
    position: relative;
    padding-bottom: toRem(88);

    @include media-breakpoint-down(md) {
      padding-top: toRem(48);
      padding-bottom: toRem(48);
    }
  }

  .testimonials__carousel {
    position: relative;

    .testimonials__items {
      position: relative;

      .testimonials__box {
        position: relative;
        display: grid;
        grid-template-columns: 51.65562% 1fr;

        @include media-breakpoint-down(lg) {
          grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column-reverse;
        }

        //testimonials left part
        .testimonials__left {
          position: relative;
          padding-top: toRem(88);
          display: flex;
          flex-direction: column;
          gap: toRem(40);

          @include media-breakpoint-down(md) {
            padding-top: 0;
            max-width: 100%;
          }

          //pattern on testimonials section dekstop
          .testimonials__background {
            position: absolute;
            width: 100%;
            overflow: hidden;
            pointer-events: none;
            margin: 0 auto;
            z-index: 1;

            @include media-breakpoint-down(md) {
              display: none;
            }

            &.testimonials__background--pattern {
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;

              .testimonials__pattern-bg {
                position: absolute;
                top: toRem(107);
                right: toRem(-2);
                display: block;

                @include media-breakpoint-down(lg) {
                  max-height: toRem(120);
                  top: toRem(152);
                  left: toRem(259);
                }
              }
            }
          }
        }

        .testimonials__title {
          max-width: toRem(416);
          color: $color-text-darker;
          display: block;

          @include media-breakpoint-down(md) {
            display: none;
          }

          &.testimonials__title--mobile {
            display: none;

            @include media-breakpoint-down(md) {
              display: block;
              max-width: toRem(500);
              margin: 0 auto toRem(24);
              text-align: center;
            }

            @include media-breakpoint-down(sm) {
              font-size: toRem(24);
            }
          }
        }

        .testimonials__detail {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: toRem(32);
          gap: toRem(16);
          background: $color-secondary;
          border-radius: toRem(2) 0 toRem(2) toRem(2);
        }

        .testi-detail__header {
          display: flex;
          flex-direction: column;
          gap: toRem(2);
        }

        .testi-detail__occupation {
          color: $color-text-lighter;
        }

        .testi-detail__name,
        .testi-detail__content {
          color: $color-white;

          @include media-breakpoint-down(md) {
            font-size: toRem(18);
          }
        }

        //testimonials right part
        .testimonials__right {
          position: relative;

          @include media-breakpoint-down(md) {
            position: relative;
            width: 100%;
            height: auto;
          }

          .testimonials-video-wrap {
            position: relative;
            width: auto;
            height: 100%;
            max-height: toRem(456);

            @include media-breakpoint-down(md) {
              position: relative;
              width: 100%;
              height: auto;
              min-height: toRem(254);
              display: grid;
            }
          }

          .testimonials-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0 0 toRem(2) 0;
          }

          .testimonial-video__buttons {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: toRem(61);
            width: toRem(61);
            border-radius: 100%;
            background: $color-white;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              pointer-events: none;

              path {
                fill: $color-primary;
              }
            }
          }
        }
      }
    }
  }

  .slick__arrows--testimonial {
    position: absolute;
    left: calc(51.65562% + toRem(32));
    bottom: toRem(114);

    @include media-breakpoint-down(xxl) {
      top: calc(toRem(456) + toRem(32));
      bottom: unset;
    }

    @include media-breakpoint-down(lg) {
      left: calc(50% + toRem(32));
      top: calc(toRem(456) + toRem(32));
    }

    @include media-breakpoint-down(md) {
      position: relative;
      left: unset;
      top: unset;
      margin: toRem(18) auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}